
export default [
  {
    path: '/areas/:hotlinkArea',
    name: 'areasArea',
    component: () => import('../components/areaDeMembros/area/Area.vue'),
    meta: ({
      logged: true,
      title: 'Área de Membros | Level Member',
      layout: 'area-layout'
    })
  },
  {
    path: '/areas/:hotlinkArea/alunos',
    name: 'areasAreaAlunos',
    component: () => import('../components/areaDeMembros/alunos/Alunos.vue'),
    meta: ({
      logged: true,
      title: 'Alunos da Área de Membros | Level Member',
      layout: 'area-layout'
    })
  },
  {
    path: '/areas/:hotlinkArea/cursos',
    name: 'areasAreaCursos',
    component: () => import('../components/areaDeMembros/cursos/Cursos.vue'),
    meta: ({
      logged: true,
      title: 'Cursos da Área de Membros | Level Member',
      layout: 'area-layout'
    })
  },
  {
    path: '/areas/:hotlinkArea/dominio',
    name: 'areasAreaDominio',
    component: () => import('../components/areaDeMembros/dominio/Dominio.vue'),
    meta: ({
      logged: true,
      title: 'Domínio da Área de Membros | Level Member',
      layout: 'area-layout'
    })
  },
  {
    path: '/areas/:hotlinkArea/integracoes',
    name: 'areasAreaIntegracoes',
    component: () => import('../components/areaDeMembros/integracoes/Integracoes.vue'),
    meta: ({
      logged: true,
      title: 'Integrações da Área de Membros | Level Member',
      layout: 'area-layout'
    })
  },
  {
    path: '/areas/:hotlinkArea/ranking',
    name: 'areasAreaRanking',
    component: () => import('../components/areaDeMembros/ranking/Ranking.vue'),
    meta: ({
      logged: true,
      title: 'Ranking da Área de Membros | Level Member',
      layout: 'area-layout'
    })
  },
  {
    path: '/areas/:hotlinkArea/:hotlinkCurso',
    name: 'areasAreaCurso',
    component: () => import('../components/areaDeMembros/curso/Curso.vue'),
    meta: ({
      logged: true,
      title: 'Curso da Área de Membros | Level Member',
      layout: 'area-layout'
    })
  },
  {
    path: '/areas/:hotlinkArea/:hotlinkCurso/:hotlinkModulo',
    name: 'areasAreaCursoModulo',
    component: () => import('../components/areaDeMembros/modulo/Modulo.vue'),
    meta: ({
      logged: true,
      title: 'Módulo do Curso | Level Member',
      layout: 'area-layout'
    })
  },
  {
    path: '/areas/:hotlinkArea/:hotlinkCurso/:hotlinkModulo/:hotlinkAula',
    name: 'areasAreaCursoModuloAula',
    component: () => import('../components/areaDeMembros/aula/Aula.vue'),
    meta: ({
      logged: true,
      title: 'Aula do Módulo | Level Member',
      layout: 'area-layout'
    })
  },
  {
    path: '/areas/:hotlinkArea/personalizacao',
    name: 'areasAreaPersonalizacao',
    component: () => import('../components/areaDeMembros/personalizacao/Personalizacao.vue'),
    meta: ({
      logged: true,
      title: 'Personalize Tudo | Level Member',
      layout: 'default-layout'
    })
  },
]
