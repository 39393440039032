
export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: "/erro"
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/app/erro/Erro.vue'),
    meta: ({
      logged: false,
      title: 'Essa página não existe... | Level Member',
      layout: 'default-layout'
    })
  },
  {
    path: '/painel',
    name: 'painel',
    component: () => import('../components/app/painel/Painel.vue'),
    meta: ({
      logged: true,
      title: 'Sua Plataforma | Level Member',
      layout: 'navbar-layout'
    })
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../components/app/perfil/Perfil.vue'),
    meta: ({
      logged: true,
      title: 'Seu Perfil de Produtor | Level Member',
      layout: 'navbar-layout'
    })
  },
  {
    path: '/areas',
    name: 'areas',
    component: () => import('../components/app/areas/Areas.vue'),
    meta: ({
      logged: true,
      title: 'Suas áreas de membros | Level Member',
      layout: 'navbar-layout'
    })
  },
  {
    path: '/comunicados',
    name: 'comunicados',
    component: () => import('../components/app/comunicados/Comunicados.vue'),
    meta: ({
      logged: true,
      title: 'Seus comunicados | Level Member',
      layout: 'navbar-layout'
    })
  },
  {
    path: '/assinatura',
    name: 'assinatura',
    component: () => import('../components/app/assinatura/Assinatura.vue'),
    meta: ({
      logged: true,
      title: 'Sua assinatura | Level Member',
      layout: 'navbar-layout'
    })
  },
  {
    path: '/notificacoes',
    name: 'notificacoes',
    component: () => import('../components/app/notificacoes/Notificacoes.vue'),
    meta: ({
      logged: true,
      title: 'Suas notificações | Level Member',
      layout: 'navbar-layout'
    })
  },
]
