<template>
  <NavArea />
  <div class="tela">
    <RouterView />
  </div>
  <Footer />
</template>

<script setup>
import { useStoreModal } from '@stores'
import NavArea from '@components/global/navArea/NavArea.vue'
import Footer from '@components/global/footer/Footer.vue'

const storeModal = useStoreModal()
</script>

<style scoped>
.tela {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100dvh;
}
</style>
